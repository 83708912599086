<template>
  <v-container fluid class="text-center">
    <v-row class="mt-5 mx-1">
      <v-col cols="12">
        <v-card>
          <v-toolbar dense dark color="secondary" elevation="2">
            <v-toolbar-title> Semáforo </v-toolbar-title>
            <v-switch
              v-model="dispatchView"
              label="Vista de Despacho"
              color="info"
              class="ml-10"
              :disabled="loading"
              :readonly="chartView"
              hide-details
            ></v-switch>
            <v-switch
              v-model="chartView"
              label="Mostrar Indicadores"
              color="info"
              class="ml-5"
              :disabled="loading"
              :readonly="dispatchView"
              hide-details
            ></v-switch>
            <v-spacer></v-spacer>
            <template v-if="dispatchView && !chartView">
              <v-select
                v-model="selectedDispatchOptions"
                :items="dispatchOptions"
                label="Filtrar por plantas"
                solo
                dense
                prepend-inner-icon="mdi-filter"
                class="mx-5"
                :disabled="loading"
                light
                multiple
                hide-details
                :close-on-select="false"
                :style="{
                  maxWidth: '400px'
                }"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="selectedDispatchOptions.length === 1">
                    <span>{{ item }}</span>
                  </v-chip>
                  <v-chip v-if="index > 0 && index + 1 === selectedDispatchOptions.length">
                    <span> {{ selectedDispatchOptions.length }} plantas seleccionadas </span>
                  </v-chip>
                </template>
              </v-select>
              <v-select
                v-model="control.despacho"
                :items="catalogues.despachos"
                label="Despacho"
                solo
                dense
                prepend-inner-icon="mdi-map-marker-plus"
                class="mx-5"
                :disabled="loading"
                light
                hide-details
                style="max-width: 300px"
                @change="getDispatchItems()"
              ></v-select>
              <v-btn text small :disabled="loading || !this.control.despacho" @click="getDispatchItems()">
                Actualizar
                <v-icon right>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <template v-if="chartView && !dispatchView">
              <v-select
                v-model="control.planta"
                :items="catalogues.plantas2"
                item-value="id"
                item-text="nombre"
                label="Planta"
                solo
                dense
                prepend-inner-icon="mdi-map-marker"
                class="mx-5"
                :disabled="loading"
                light
                hide-details
                style="max-width: 300px"
                @change="getChartItems()"
              >
              </v-select>
              <v-select
                v-model="control.indicador"
                :items="catalogues.indicadores"
                label="Indicador"
                solo
                dense
                prepend-inner-icon="mdi-file-cog"
                class="mx-5"
                :disabled="loading"
                light
                hide-details
                style="max-width: 300px"
                @change="getChartItems()"
              >
              </v-select>
              <v-btn
                text
                small
                :disabled="loading || !this.control.planta || !this.control.indicador"
                @click="getChartItems()"
              >
                Actualizar
                <v-icon right>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <template v-else-if="!chartView && !dispatchView">
              <v-select
                v-model="control.planta"
                :items="catalogues.plantas2"
                item-value="id"
                item-text="nombre"
                label="Planta"
                solo
                dense
                prepend-inner-icon="mdi-map-marker"
                class="mx-5"
                :disabled="loading"
                light
                hide-details
                style="max-width: 300px"
                @change="getItems()"
              >
              </v-select>
              <v-menu v-if="this.control.planta" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small :disabled="loading" v-bind="attrs" v-on="on">
                    Ajustes
                    <v-icon right>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showPhone()">
                    <v-list-item-title>Teléfonos <v-icon small>mdi-cellphone</v-icon></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu v-if="this.control.planta" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small :disabled="loading" v-bind="attrs" v-on="on">
                    Reportes
                    <v-icon right>mdi-file-excel</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="downloadStatusReport()">
                    <v-list-item-title>Reporte de estatus</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="downloadTravelReport()">
                    <v-list-item-title>Reporte de viajes</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="downloadTimeReport()">
                    <v-list-item-title>Reporte de tiempos</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn text small :disabled="loading || !this.control.planta" @click="showPending()">
                Pendientes
                <v-icon right>mdi-clock-outline</v-icon>
              </v-btn>
              <v-btn text small :disabled="loading || !this.control.planta" @click="dialogs.update = true">
                Editar
                <v-icon right>mdi-pencil</v-icon>
              </v-btn>
              <v-btn text small :disabled="loading || !this.control.planta" @click="getItems()">
                Actualizar
                <v-icon right>mdi-refresh</v-icon>
              </v-btn>
            </template>
          </v-toolbar>
          <v-card-text v-if="!dispatchView && !chartView">
            <v-simple-table fixed-header height="600px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <template v-for="(item, idx) in headers">
                      <th class="text-center" :key="idx">
                        <v-chip small color="blue-grey lighten-2" class="mb-2 white--text"> {{ idx + 1 }} </v-chip
                        ><br />
                        <v-btn
                          x-small
                          block
                          :color="item.color"
                          class="elevation-0 white--text mb-3"
                          @click="showPriority(item, idx)"
                        >
                          {{ item.nombre }}
                        </v-btn>
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, idx) in items">
                    <tr :key="idx">
                      <template v-for="(item2, idx2) in item">
                        <td :key="idx2">
                          <v-btn
                            block
                            :color="
                              item2.unidad
                                ? item2.comentario
                                  ? 'amber lighten-4'
                                  : item2.data && item2.data.FechaInicioLavado
                                  ? 'cyan lighten-4'
                                  : 'blue-grey lighten-5'
                                : 'white'
                            "
                            :disabled="!item2.unidad"
                            height="60px"
                            class="my-3 text-normal"
                            @contextmenu.prevent="showTravel(item2)"
                            @click="viewUpdate(item2)"
                          >
                            <p class="mt-3">
                              <span class="font-weight-bold" style="font-size: 15px">{{
                                item2.unidad ? item2.unidad : ''
                              }}</span
                              ><br />
                              <span
                                v-if="item2.data && item2.data.flag"
                                class="font-weight-bold"
                                style="font-size: 12px; color: #f44336"
                                >{{ item2.fecha ? getTime(item2.fecha, item2.data, item2.estatus) : '' }}</span
                              >
                              <span v-else class="font-weight-bold" style="font-size: 12px; color: #0d47a1">{{
                                item2.fecha ? getTime(item2.fecha, item2.data, item2.estatus) : ''
                              }}</span
                              ><br />
                              <span v-if="item2.viajes" class="font-weight-bold" style="font-size: 12px"
                                >{{ item2.viajes }}v</span
                              >
                            </p>
                          </v-btn>
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text v-else-if="dispatchView">
            <v-simple-table fixed-header height="80vh">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-center green lighten-5">
                      <v-btn x-small block color="blue" class="elevation-0 white--text mb-3"> ACTIVAS </v-btn>
                    </th>
                    <template v-for="(item, idx) in headers">
                      <th class="text-center" :key="idx">
                        <v-btn x-small block :color="item.color" class="elevation-0 white--text mb-3">
                          {{ item.nombre }}
                        </v-btn>
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, plantName) in filteredDispatchItems">
                    <tr @click="handleChangePlantDetailVisible(plantName)">
                      <td class="text-center">
                        <v-btn small block color="blue-grey lighten-4" class="elevation-0 mb-3">
                          <strong>{{ plantName }}</strong>
                        </v-btn>
                      </td>
                      <td class="text-center green lighten-5">
                        <v-btn small block class="elevation-0 mb-3">
                          <strong style="font-size: 14px">{{ item['ACTIVAS'] ? item['ACTIVAS'].length : 0 }}</strong>
                        </v-btn>
                      </td>
                      <template v-for="(item2, idx2) in headers">
                        <td class="text-center" :key="idx2">
                          <v-btn small block class="elevation-0 mb-3">
                            <strong style="font-size: 14px">{{
                              item[item2.nombre] ? item[item2.nombre].length : 0
                            }}</strong>
                          </v-btn>
                        </td>
                      </template>
                    </tr>

                    <tr v-if="visiblePlantDetails.some((aux) => plantName === aux)">
                      <td class="text-center"></td>
                      <td class="text-center"></td>
                      <template v-for="(item2, idx2) in headers">
                        <td :key="idx2" style="vertical-align: top">
                          <template v-if="item[item2.nombre]">
                            <template v-for="subItem in item[item2.nombre]">
                              <v-btn
                                block
                                :color="
                                  subItem.unidad
                                    ? subItem.comentario
                                      ? 'amber lighten-4'
                                      : subItem.data && subItem.data.FechaInicioLavado
                                      ? 'cyan lighten-4'
                                      : 'blue-grey lighten-5'
                                    : 'white'
                                "
                                :disabled="!subItem.unidad"
                                height="60px"
                                class="my-3 text-normal"
                                @contextmenu.prevent="showTravel(subItem)"
                                @click="viewUpdate(subItem)"
                              >
                                <p class="mt-3">
                                  <span class="font-weight-bold" style="font-size: 15px">{{
                                    subItem.unidad ? subItem.unidad : ''
                                  }}</span
                                  ><br />
                                  <span
                                    v-if="subItem.data && subItem.data.flag"
                                    class="font-weight-bold"
                                    style="font-size: 12px; color: #f44336"
                                    >{{
                                      subItem.fecha ? getTime(subItem.fecha, subItem.data, subItem.estatus) : ''
                                    }}</span
                                  >
                                  <span v-else class="font-weight-bold" style="font-size: 12px; color: #0d47a1">{{
                                    subItem.fecha ? getTime(subItem.fecha, subItem.data, subItem.estatus) : ''
                                  }}</span
                                  ><br />
                                  <span v-if="subItem.viajes" class="font-weight-bold" style="font-size: 12px"
                                    >{{ subItem.viajes }}v</span
                                  >
                                </p>
                              </v-btn>
                            </template>
                          </template>
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text v-else-if="chartView">
            <v-row justify="center">
              <v-col cols="2">
                <v-dialog
                  ref="startDateDialog"
                  v-model="dialogs.startDate"
                  :return-value.sync="chart.fecha_inicio"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="chart.fecha_inicio"
                      label="Fecha inicio"
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="chart.fecha_inicio" locale="es" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialogs.startDate = false"> Cancelar </v-btn>
                    <v-btn text color="primary" @click="$refs.startDateDialog.save(chart.fecha_inicio)">
                      Aceptar
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="2">
                <v-dialog
                  ref="endDateDialog"
                  v-model="dialogs.endDate"
                  :return-value.sync="chart.fecha_fin"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="chart.fecha_fin"
                      label="Fecha fin"
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="chart.fecha_fin" locale="es" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialogs.endDate = false"> Cancelar </v-btn>
                    <v-btn text color="primary" @click="$refs.endDateDialog.save(chart.fecha_fin)"> Aceptar </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <Line-chart :chart-data="chart.data" :options="chart.options"></Line-chart>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Editar unidad</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.nombre"
                    label="Nombre"
                    :rules="rules.nombre"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="forms.update.estatus"
                    label="Estatus"
                    :items="catalogues.estatus"
                    item-text="nombre"
                    item-value="id"
                    :rules="rules.estatus"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="forms.update.comentario"
                    label="Comentario"
                    rows="2"
                    :rules="rules.comentario"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            outlined
                            icon
                            color="blue-grey"
                            class="mt-2"
                            :loading="loading"
                            @click="updateComment()"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>Actualizar</span>
                      </v-tooltip>
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="forms.update.planta"
                    label="Planta"
                    :items="catalogues.plantas"
                    item-text="nombre"
                    item-value="id"
                    :rules="rules.planta"
                    :disabled="loading"
                    dense
                    outlined
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="forms.update.id" class="mt-2" justify="center">
                <v-btn
                  outlined
                  small
                  color="primary"
                  class="mr-3"
                  :loading="loading"
                  :disabled="!forms.update.data"
                  @click="viewDetail(forms.update.data)"
                >
                  Detalle
                  <v-icon right>mdi-file-document</v-icon>
                </v-btn>
                <v-btn
                  outlined
                  small
                  color="blue-grey"
                  class="mr-3"
                  :loading="loading"
                  :disabled="!forms.update.data"
                  @click="viewTime(forms.update.data)"
                >
                  Tiempos
                  <v-icon right>mdi-clock-time-three</v-icon>
                </v-btn>
                <v-btn outlined small color="error" :loading="loading" @click="remove()">
                  Eliminar
                  <v-icon right>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.detail" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Detalle</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <template v-for="(item, idx) in forms.detail">
                    <tr v-if="item.name.toLowerCase() !== 'flag'" :key="idx" class="justify-center">
                      <td class="font-weight-bold">
                        {{ item.name.replace(/\_/g, ' ') }}
                      </td>
                      <td v-if="item.name.toLowerCase() === 'location'">
                        <v-btn fab x-small color="primary" class="elevation-0" @click="viewMap(item.value)">
                          <v-icon>mdi-google-maps</v-icon>
                        </v-btn>
                      </td>
                      <td v-else style="color: #0d47a1">{{ item.value }}</td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.pending" persistent max-width="800px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Pendientes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headersPending"
              :items="forms.pending"
              item-class="class"
              :footer-props="{
                'items-per-page-text': 'Elementos por página',
                'items-per-page-options': [20, 50, -1]
              }"
              :items-per-page="10"
              class="elevation-1"
              dense
            >
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de
                {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.travel" persistent max-width="1250px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Detalle de viajes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headersTravel"
              :items="forms.travel"
              item-class="class"
              :footer-props="{
                'items-per-page-text': 'Elementos por página',
                'items-per-page-options': [20, 50, -1]
              }"
              :items-per-page="10"
              class="elevation-1"
              dense
            >
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de
                {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.time" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Tiempos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headersTime"
              :items="forms.time"
              item-class="class"
              :footer-props="{
                'items-per-page-text': 'Elementos por página',
                'items-per-page-options': [20, 50, -1]
              }"
              :items-per-page="10"
              class="elevation-1"
              dense
            >
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de
                {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.phone" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar teléfonos de planta </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="updatePhone()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formPhone" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.settings.phone"
                    label="Teléfono"
                    :rules="rules.telefono"
                    placeholder="Código de país + 10 dígitos"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.settings.phone2"
                    label="Teléfono 2"
                    :rules="rules.telefono"
                    placeholder="Código de país + 10 dígitos"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.settings.phone3"
                    label="Teléfono 3"
                    :rules="rules.telefono"
                    placeholder="Código de país + 10 dígitos"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.settings.phone4"
                    label="Teléfono 4"
                    :rules="rules.telefono"
                    placeholder="Código de país + 10 dígitos"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.settings.phone5"
                    label="Teléfono 5"
                    :rules="rules.telefono"
                    placeholder="Código de país + 10 dígitos"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.priority" persistent max-width="1100px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Prioridad sugerida</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headersPriority"
              :items="forms.priority"
              item-class="class"
              :footer-props="{
                'items-per-page-text': 'Elementos por página',
                'items-per-page-options': [20, 50, -1]
              }"
              :items-per-page="10"
              class="elevation-1"
              dense
            >
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de
                {{ props.itemsLength }}
              </template>
              <template v-slot:no-data> No hay información disponible </template>
              <template v-slot:no-results> No se obtuvieron resultados </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.reports.travel" persistent max-width="500px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Reporte de viajes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="downloadTravelReport()">
            Descargar
            <v-icon right>mdi-download</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center" class="my-5">
            <v-date-picker
              v-model="forms.reports.travel.fechas"
              color="primary"
              elevation="2"
              range
              locale="es"
              :disabled="loading"
            ></v-date-picker>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.reports.time" persistent max-width="500px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Reporte de tiempos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="downloadTimeReport()">
            Descargar
            <v-icon right>mdi-download</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center" class="my-5">
            <v-date-picker
              v-model="forms.reports.time.fechas"
              color="primary"
              elevation="2"
              range
              locale="es"
              :disabled="loading"
            ></v-date-picker>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay absolute :value="loading || !company" opacity="0.9">
      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
      <div v-else-if="!loading && !company" class="text-center">Seleccionar una empresa...</div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'
import moment from 'moment-timezone'
import writeXlsxFile from 'write-excel-file'
import LineChart from '../components/Charts/Line.js'
import _ from 'lodash'

export default {
  name: 'semaphore',
  components: {
    LineChart
  },
  data: () => ({
    dispatchOptions: [],
    selectedDispatchOptions: [],
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    headers: [],
    headersPending: [
      { text: 'Order', align: 'left', sortable: false, value: 'Order' },
      { text: 'Load', align: 'left', sortable: false, value: 'Load' },
      { text: 'Customer', align: 'left', sortable: false, value: 'Customer' },
      { text: 'JobSite', align: 'left', sortable: false, value: 'JobSite' },
      {
        text: 'LoadingDate',
        align: 'left',
        sortable: false,
        value: 'LoadingDate'
      }
    ],
    headersTravel: [
      { text: 'Order', align: 'left', sortable: false, value: 'Order' },
      { text: 'Load', align: 'left', sortable: false, value: 'Load' },
      { text: 'JobSite', align: 'left', sortable: false, value: 'JobSite' },
      { text: 'M3', align: 'left', sortable: false, value: 'M3' },
      {
        text: 'TravelTime',
        align: 'left',
        sortable: false,
        value: 'TravelTime'
      },
      { text: 'ProdDesc', align: 'left', sortable: false, value: 'ProdDesc' },
      { text: 'Operador', align: 'left', sortable: false, value: 'Operador' },
      {
        text: 'TipoOperador',
        align: 'left',
        sortable: false,
        value: 'TipoOperador'
      },
      {
        text: 'RealLoadingStart',
        align: 'left',
        sortable: false,
        value: 'RealLoadingStart'
      }
    ],
    headersPriority: [
      { text: '#', align: 'left', sortable: false, value: 'Index' },
      { text: 'Plate', align: 'left', sortable: false, value: 'Plate' },
      { text: 'Driver', align: 'left', sortable: false, value: 'Driver' },
      { text: 'Trips', align: 'left', sortable: false, value: 'Trips' },
      { text: 'M3', align: 'left', sortable: false, value: 'M3' },
      { text: 'AverageM3', align: 'left', sortable: false, value: 'AverageM3' },
      {
        text: 'AverageStay',
        align: 'left',
        sortable: false,
        value: 'AverageStay'
      },
      {
        text: 'AverageTravelCycle',
        align: 'left',
        sortable: false,
        value: 'AverageTravelCycle'
      },
      {
        text: 'AverageTotalTime',
        align: 'left',
        sortable: false,
        value: 'AverageTotalTime'
      },
      { text: 'TotalTime', align: 'left', sortable: false, value: 'TotalTime' }
    ],
    headersTime: [
      { text: 'Name', align: 'left', sortable: false, value: 'Name' },
      { text: 'Time', align: 'left', sortable: false, value: 'Time' },
      { text: 'Order', align: 'left', sortable: false, value: 'Order' },
      { text: 'Load', align: 'left', sortable: false, value: 'Load' },
      { text: 'JobSite', align: 'left', sortable: false, value: 'JobSite' }
    ],
    selected: null,
    dispatchView: false,
    chartView: false,
    catalogues: {
      plantas: [],
      plantas2: [],
      estatus: [],
      despachos: [],
      indicadores: [
        { text: 'Tiempo de estadía', value: 'stay' },
        { text: 'Tiempo de ciclo de viaje', value: 'cycle' },
        { text: 'Tiempo de estadía + ciclo', value: 'all' }
      ]
    },
    control: {
      planta: null,
      despacho: null,
      indicador: null,
      interval: null
    },
    items: [],
    dispatchItems: [],
    chartItems: [],
    extraInfo: {},
    chart: {
      data: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Minutos'
              }
            }
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Hora del día'
              }
            }
          ]
        },
        tooltips: {}
      },
      fecha_inicio: null,
      fecha_fin: null
    },
    forms: {
      update: {
        id: null,
        nombre: '',
        estatus: '',
        comentario: '',
        planta: '',
        data: null
      },
      detail: [],
      time: [],
      pending: [],
      travel: [],
      priority: [],
      settings: {
        phone: '',
        phone2: '',
        phone3: '',
        phone4: '',
        phone5: ''
      },
      reports: {
        travel: {
          fechas: []
        },
        time: {
          fechas: []
        }
      }
    },
    rules: {
      nombre: [
        (v) => !!v || 'El campo es requerido',
        (v) => (v && v.length <= 10) || 'El campo excede la longitud máxima'
      ],
      telefono: [(v) => /^[+0-9]{0,15}?$/.test(v) || v === '' || 'El campo es inválido'],
      estatus: [(v) => !!v || 'El campo es requerido'],
      comentario: [(v) => _.isNil(v) || v.length <= 200 || 'El campo excede la longitud máxima'],
      planta: [(v) => !!v || 'El campo es requerido']
    },
    dialogs: {
      update: false,
      detail: false,
      time: false,
      remove: false,
      pending: false,
      travel: false,
      priority: false,
      phone: false,
      reports: {
        travel: false,
        time: false
      },
      startDate: false,
      endDate: false
    },
    visiblePlantDetails: []
  }),
  mounted() {
    this.refresh(this.$store.state.general.company)
    this.getStatus()
    this.getDispatch()
    this.setDates()
  },
  computed: {
    filteredDispatchItems() {
      if (!this.selectedDispatchOptions[0]) {
        return this.dispatchItems
      }

      let aux = this.selectedDispatchOptions.reduce((acc, value) => {
        acc[value] = { ...this.dispatchItems[value] }
        return acc
      }, {})

      return aux
    },
    company() {
      return this.$store.state.general.company
    },
    plant() {
      return this.control.planta
    }
  },
  watch: {
    company: function (val) {
      this.loading = true
      this.clear()
      setTimeout(() => {
        this.refresh(val)
      }, 500)
    },
    dispatchView: function (val) {
      if (val) {
        this.getDispatchItems()
      } else {
        this.getItems()
      }
    },
    chartView: function (val) {
      if (val) {
        this.getChartItems()
      } else {
        this.getItems()
      }
    }
  },
  methods: {
    handleChangePlantDetailVisible(name) {
      if (name === 'TOTAL ' + this.control.despacho) {
        return
      }
      const index = this.visiblePlantDetails.indexOf(name)
      if (index === -1) {
        this.visiblePlantDetails.push(name)
        return
      }
      this.visiblePlantDetails.splice(index, 1)
    },
    async getStatus() {
      const status = await this.axios.get(services.routes.semaphore + '/status')
      if (status.data.data) {
        this.headers = status.data.data
        for (let estatus of status.data.data) {
          this.catalogues.estatus.push({
            id: estatus.id,
            nombre: estatus.nombre
          })
        }
      }
    },
    async getDispatch() {
      const dispatch = await this.axios.get(services.routes.semaphore + '/dispatch')
      if (dispatch.data.data) {
        for (let key in dispatch.data.data) {
          this.catalogues.despachos.push(key)
        }
      }
    },
    refresh(id) {
      if (!id) {
        return
      }
      if (this.dispatchView) {
        this.getDispatchItems()
        return
      }
      this.loading = true
      this.axios
        .get(services.routes.user + '/plant' + '?id=' + this.$store.state.general.company)
        .then((response) => {
          this.catalogues.plantas = response.data.data
          this.catalogues.plantas2 = []
          const excludeCodes = new Set(['D144', 'D354', 'D120', 'D677'])
          const renameMap = {
            D083: 'MX-PD MINAS I, III',
            D081: 'MX-PD ARMAS I, II',
            D105: 'MX-PD NAUCALPAN II, III',
            D136: 'MX-PD ESTRELLA I, II'
          }

          for (let planta of this.catalogues.plantas) {
            if (excludeCodes.has(planta.codigo)) {
              continue
            }

            let newPlanta = JSON.parse(JSON.stringify(planta))

            if (renameMap[planta.codigo]) {
              newPlanta.nombre = renameMap[planta.codigo]
            }

            this.catalogues.plantas2.push(newPlanta)
          }
        })
        .catch((error) => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    clear() {
      this.catalogues.plantas = []
      this.control = {
        planta: null,
        despacho: null
      }
      this.items = []
      this.dispatchItems = []
      clearInterval(this.control.interval)
    },
    cancel() {
      const actions = {
        detail: () => {
          this.forms.detail = []
          this.dialogs.detail = false
        },
        time: () => {
          this.forms.time = []
          this.dialogs.time = false
        },
        update: () => {
          this.$refs.formUpdate.reset()
          this.forms.update = {
            id: null,
            nombre: '',
            estatus: '',
            comentario: '',
            planta: '',
            data: null
          }
          this.dialogs.update = false
        },
        pending: () => {
          this.forms.pending = []
          this.dialogs.pending = false
        },
        travel: () => {
          this.forms.travel = []
          this.dialogs.travel = false
        },
        priority: () => {
          this.forms.priority = []
          this.dialogs.priority = false
        },
        phone: () => {
          this.dialogs.phone = false
        },
        remove: () => {
          this.deleted = null
          this.dialogs.remove = false
        },
        'reports.travel': () => {
          this.forms.reports.travel.fechas = []
          this.dialogs.reports.travel = false
        },
        'reports.time': () => {
          this.forms.reports.time.fechas = []
          this.dialogs.reports.time = false
        }
      }

      // Verifica cuál propiedad está activa y ejecuta la acción correspondiente
      for (let key in actions) {
        if (_.get(this.dialogs, key)) {
          actions[key]()
          break
        }
      }
    },
    getItems() {
      if (!this.dispatchView) {
        if (this.control.planta) {
          this.loading = true
          this.axios
            .get(
              services.routes.semaphore +
                '/item' +
                '?empresa=' +
                this.$store.state.general.company +
                '&planta=' +
                this.control.planta
            )
            .then((response) => {
              let _items = response.data.data
              this.setFlag(_items)
              this.items = _items
              for (let planta of this.catalogues.plantas) {
                if (planta.id === this.control.planta) {
                  this.forms.settings.phone = planta.telefono ? planta.telefono : ''
                  this.forms.settings.phone2 = planta.telefono2 ? planta.telefono2 : ''
                  this.forms.settings.phone3 = planta.telefono3 ? planta.telefono3 : ''
                  this.forms.settings.phone4 = planta.telefono4 ? planta.telefono4 : ''
                  this.forms.settings.phone5 = planta.telefono5 ? planta.telefono5 : ''
                }
              }
            })
            .catch((error) => {
              this.error = true
              this.msgError = error.response.data.message || 'Servicio no disponible'
            })
            .finally(() => {
              this.loading = false
              if (!this.control.interval) {
                this.setInterval()
              }
            })
        } else {
          this.error = true
          this.msgError = 'Debe seleccionar una planta'
        }
      }
    },
    getDispatchItems() {
      if (!this.dispatchView) {
        return
      }
      if (this.catalogues.despachos.length && !this.control.despacho) {
        this.control.despacho = this.catalogues.despachos[0]
      }
      if (!this.control.despacho) {
        this.error = true
        this.msgError = 'Debe seleccionar un despacho'
        return
      }
      this.loading = true
      this.axios
        .get(
          services.routes.semaphore +
            '/item/dispatch' +
            '?empresa=' +
            this.$store.state.general.company +
            '&despacho=' +
            this.control.despacho
        )
        .then((response) => {
          this.dispatchItems = response.data.data
          const total_despacho = {}
          const validKeys = new Set([
            'PREP LAV',
            'PREP LAVADO',
            'COMIENDO',
            'COMIDA',
            'TIRAR CONCRETO',
            'DISPONIBLE',
            'VIAJE'
          ])

          for (let key in this.dispatchItems) {
            const currentDispatch = this.dispatchItems[key]
            currentDispatch['ACTIVAS'] = []
            currentDispatch['visible'] = true

            for (let key2 in currentDispatch) {
              const currentItems = currentDispatch[key2]

              if (validKeys.has(key2) && currentItems.length) {
                currentDispatch['ACTIVAS'].push(...Array(currentItems.length).fill([]))
              }

              if (!_.has(total_despacho, key2)) {
                total_despacho[key2] = []
              }

              if (currentItems.length) {
                total_despacho[key2].push(...Array(currentItems.length).fill({}))
              }
            }
          }

          this.dispatchOptions = Object.keys(this.dispatchItems)
          this.dispatchItems['TOTAL ' + this.control.despacho] = total_despacho
        })
        .catch((error) => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
          if (!this.control.interval) {
            this.setInterval()
          }
        })
    },
    getChartItems() {
      if (this.chartView) {
        if (this.catalogues.plantas.length && !this.control.planta) {
          this.control.planta = this.catalogues.plantas[0].id
        }
        if (this.catalogues.indicadores.length && !this.control.indicador) {
          this.control.indicador = this.catalogues.indicadores[0].value
        }
        if (this.control.planta && this.control.indicador) {
          if (this.chart.fecha_inicio && this.chart.fecha_fin) {
            this.loading = true
            this.axios
              .get(
                services.routes.semaphore +
                  '/item/chart' +
                  '?empresa=' +
                  this.$store.state.general.company +
                  '&planta=' +
                  this.control.planta +
                  '&indicador=' +
                  this.control.indicador +
                  '&fecha_inicio=' +
                  this.chart.fecha_inicio +
                  '&fecha_fin=' +
                  this.chart.fecha_fin
              )
              .then((response) => {
                this.chartItems = response.data.data
                this.extraInfo = {}
                this.chart.options.tooltips = {
                  callbacks: {
                    label: (tooltipItem, data) => {
                      let label = data.datasets[tooltipItem.datasetIndex].label
                      return label
                    },
                    footer: (tooltipItems, data) => {
                      let list = []
                      for (let i = 0; i < tooltipItems.length; i++) {
                        list = list.concat([''])
                        let label = data.datasets[tooltipItems[i].datasetIndex].label
                        let value = data.datasets[tooltipItems[i].datasetIndex].data[tooltipItems[i].index]
                        let key = label + ' ' + tooltipItems[i].index
                        list = list.concat([
                          'Time: ' + value + ' min',
                          'Order: ' + this.extraInfo[key].order,
                          'Load: ' + this.extraInfo[key].load,
                          'JobSite: ' + this.extraInfo[key].jobsite
                        ])
                      }
                      return list
                    }
                  }
                }
                this.makeChart(this.chartItems)
              })
              .catch((error) => {
                this.error = true
                this.msgError = error.response.data.message || 'Servicio no disponible'
              })
              .finally(() => {
                this.loading = false
                if (!this.control.interval) {
                  this.setInterval()
                }
              })
          } else {
            this.error = true
            this.msgError = 'Debe seleccionar un rango de fechas'
          }
        } else {
          this.error = true
          this.msgError = 'Debe seleccionar una planta y un indicador'
        }
      }
    },
    makeChart(data) {
      this.chart.data = null
      const datasets = []
      for (let key in data.stay) {
        datasets.push({
          label: '(Estadía) ' + key,
          borderColor: 'rgb(248, 150, 121)',
          backgroundColor: 'rgb(248, 150, 121)',
          data: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          ],
          borderWidth: 1,
          lineTension: 0,
          fill: true,
          showLine: false
        })
        for (let i = 0; i < data.stay[key].time.length; i++) {
          if (!datasets[datasets.length - 1].data[data.stay[key].time[i].hour]) {
            datasets[datasets.length - 1].data[data.stay[key].time[i].hour] = 0
          }
          datasets[datasets.length - 1].data[data.stay[key].time[i].hour] += data.stay[key].time[i].min
          let key2 = '(Estadía) ' + key + ' ' + data.stay[key].time[i].hour.toString()
          this.extraInfo[key2] = data.stay[key].time[i]
        }
      }
      for (let key in data.cycle) {
        datasets.push({
          label: '(Ciclo de viaje) ' + key,
          borderColor: 'rgb(121, 192, 248)',
          backgroundColor: 'rgb(121, 192, 248)',
          data: [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          ],
          borderWidth: 1,
          lineTension: 0,
          fill: true,
          showLine: false
        })
        for (let i = 0; i < data.cycle[key].time.length; i++) {
          if (!datasets[datasets.length - 1].data[data.cycle[key].time[i].hour]) {
            datasets[datasets.length - 1].data[data.cycle[key].time[i].hour] = 0
          }
          datasets[datasets.length - 1].data[data.cycle[key].time[i].hour] += data.cycle[key].time[i].min
          let key2 = '(Ciclo de viaje) ' + key + ' ' + data.cycle[key].time[i].hour.toString()
          this.extraInfo[key2] = data.cycle[key].time[i]
        }
      }
      this.chart.data = {
        labels: [
          '00:00',
          '01:00',
          '02:00',
          '03:00',
          '04:00',
          '05:00',
          '06:00',
          '07:00',
          '08:00',
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00'
        ],
        datasets: datasets
      }
    },
    setDates() {
      this.chart.fecha_inicio = moment().format('YYYY-MM-DD')
      this.chart.fecha_fin = moment().format('YYYY-MM-DD')
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        data.empresa = this.$store.state.general.company
        this.axios
          .put(services.routes.semaphore + '/item', { data })
          .then((response) => {
            this.cancel()
            this.getItems()
            this.success = true
            this.msgSuccess = 'Unidad actualizada exitosamente'
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    setFlag(items) {
      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < items[i].length; j++) {
          if (items[i][j].estatus === 'A_CLIENTE' && items[i][j].data && items[i][j].data.TravelTime) {
            let tt = parseInt(items[i][j].data.TravelTime.replace('min', '').trim())
            let diff = moment.utc().diff(moment(items[i][j].fecha), 'minutes')
            if (diff > tt) {
              items[i][j].data.flag = true
            }
          }
        }
      }
    },
    getTime(date, data, estatus) {
      const travelTime = estatus === 'A_CLIENTE' && data && data.TravelTime ? ' / ' + data.TravelTime : ' min'
      return moment.utc().diff(moment(date), 'minutes') + travelTime
    },
    showTravel(data) {
      this.loading = true
      this.axios
        .get(
          services.routes.semaphore +
            '/travel' +
            '?empresa=' +
            this.$store.state.general.company +
            '&planta=' +
            this.control.planta +
            '&unidad=' +
            data.unidad
        )
        .then((response) => {
          this.forms.travel = response.data.data
          this.forms.travel.sort((a, b) => moment(a.RealLoadingStart).diff(moment(b.RealLoadingStart)))
          this.dialogs.travel = true
        })
        .catch((error) => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    showPriority(data, idx) {
      if (data.id === 'DISPONIBLE') {
        this.loading = true
        let params = {
          unidades: []
        }
        for (let i = 0; i < this.items.length; i++) {
          for (let j = 0; j < this.items[i].length; j++) {
            if (j == idx && this.items[i][j].unidad) {
              params.unidades.push(this.items[i][j].unidad)
            }
          }
        }
        this.axios
          .get(
            services.routes.semaphore +
              '/priority' +
              '?empresa=' +
              this.$store.state.general.company +
              '&planta=' +
              this.control.planta,
            { params }
          )
          .then((response) => {
            this.forms.priority = response.data.data
            this.dialogs.priority = true
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    viewUpdate(data) {
      this.forms.update = {
        id: data.id,
        nombre: data.unidad,
        estatus: data.estatus,
        comentario: data.comentario,
        planta: data.planta,
        data: data.data
      }
      this.dialogs.update = true
    },
    updateComment() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        data.empresa = this.$store.state.general.company
        this.axios
          .put(services.routes.semaphore + '/item/comment', { data })
          .then((response) => {
            this.cancel()
            this.getItems()
            this.success = true
            this.msgSuccess = 'Comentario actualizado exitosamente'
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    viewDetail(data) {
      for (let key in data) {
        this.forms.detail.push({
          name: key,
          value: data[key]
        })
      }
      this.dialogs.detail = true
    },
    viewTime(data) {
      if (this.control.planta) {
        this.loading = true
        this.axios
          .get(
            services.routes.semaphore +
              '/time' +
              '?empresa=' +
              this.$store.state.general.company +
              '&planta=' +
              this.control.planta +
              '&unidad=' +
              this.forms.update.nombre
          )
          .then((response) => {
            this.forms.time = response.data.data
            this.dialogs.time = true
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.error = true
        this.msgError = 'Debe seleccionar una planta'
      }
    },
    viewMap(link) {
      window.open(link, '_blank')
    },
    remove() {
      this.loading = true
      let data = {
        id: this.forms.update.id,
        nombre: this.forms.update.nombre,
        estatus: this.forms.update.estatus,
        planta: this.forms.update.planta,
        empresa: this.$store.state.general.company
      }
      this.axios
        .delete(services.routes.semaphore + '/item', { data: { data } })
        .then((response) => {
          this.cancel()
          this.getItems()
          this.success = true
          this.msgSuccess = 'Unidad eliminada exitosamente'
        })
        .catch((error) => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    showPending() {
      if (this.control.planta) {
        this.loading = true
        this.axios
          .get(
            services.routes.semaphore +
              '/pending' +
              '?empresa=' +
              this.$store.state.general.company +
              '&planta=' +
              this.control.planta
          )
          .then((response) => {
            this.forms.pending = response.data.data
            this.forms.pending.sort((a, b) => moment(b.LoadingDate).diff(moment(a.LoadingDate)))
            this.setDelayedOrders()
            this.dialogs.pending = true
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.error = true
        this.msgError = 'Debe seleccionar una planta'
      }
    },
    showPhone() {
      if (this.control.planta) {
        this.dialogs.phone = true
      } else {
        this.error = true
        this.msgError = 'Debe seleccionar una planta'
      }
    },
    updatePhone() {
      if (this.$refs.formPhone.validate()) {
        this.loading = true
        let data = {
          empresa: this.$store.state.general.company,
          planta: this.control.planta,
          telefono: this.forms.settings.phone,
          telefono2: this.forms.settings.phone2,
          telefono3: this.forms.settings.phone3,
          telefono4: this.forms.settings.phone4,
          telefono5: this.forms.settings.phone5
        }
        this.axios
          .put(services.routes.semaphore + '/updatePhone', { data })
          .then((response) => {
            this.cancel()
            this.success = true
            this.msgSuccess = 'Teléfonos actualizados exitosamente'
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    setDelayedOrders() {
      for (let i = 0; i < this.forms.pending.length; i++) {
        if (this.forms.pending[i].LoadingDate) {
          const diff = moment.utc().diff(moment(this.forms.pending[i].LoadingDate), 'minutes')
          if (diff > 0) {
            this.forms.pending[i].class = 'delayed-order'
          }
        }
      }
    },
    downloadStatusReport() {
      this.loading = true
      this.axios
        .get(
          services.routes.semaphore +
            '/report/status' +
            '?empresa=' +
            this.$store.state.general.company +
            '&planta=' +
            this.control.planta
        )
        .then((response) => {
          if (response.data.data.length) {
            let data = []
            let headers = []
            for (let key in response.data.data[0]) {
              headers.push({
                value: key,
                fontWeight: 'bold'
              })
            }
            data.push(headers)
            for (let i = 0; i < response.data.data.length; i++) {
              let rows = []
              for (let key in response.data.data[0]) {
                rows.push({
                  type: String,
                  value: response.data.data[i][key]
                })
              }
              data.push(rows)
            }
            writeXlsxFile(data, {
              fileName: 'Reporte de estatus ' + moment().format('YYYY-MM-DD') + '.xlsx'
            })
          } else {
            this.error = true
            this.msgError = 'No hay información disponible'
          }
        })
        .catch((error) => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    downloadTravelReport() {
      if (this.dialogs.reports.travel) {
        this.loading = true
        this.forms.reports.travel.fechas.sort((a, b) => moment(a).diff(moment(b)))
        let fecha_inicio = this.forms.reports.travel.fechas[0]
        let fecha_fin = this.forms.reports.travel.fechas[1]
        this.axios
          .get(
            services.routes.semaphore +
              '/report/travel' +
              '?empresa=' +
              this.$store.state.general.company +
              '&planta=' +
              this.control.planta +
              '&fecha_inicio=' +
              fecha_inicio +
              '&fecha_fin=' +
              fecha_fin
          )
          .then((response) => {
            response.data.data.sort((a, b) => moment(a.RealLoadingStart).diff(moment(b.RealLoadingStart)))
            if (response.data.data.length) {
              let data = []
              let headers = []
              for (let key in response.data.data[0]) {
                headers.push({
                  value: key,
                  fontWeight: 'bold'
                })
              }
              data.push(headers)
              for (let i = 0; i < response.data.data.length; i++) {
                let rows = []
                for (let key in response.data.data[0]) {
                  rows.push({
                    type: String,
                    value: !_.isNil(response.data.data[i][key]) ? response.data.data[i][key].toString() : ''
                  })
                }
                data.push(rows)
              }
              writeXlsxFile(data, { fileName: 'Reporte de viajes.xlsx' })
              this.cancel()
            } else {
              this.error = true
              this.msgError = 'No hay información disponible'
            }
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.dialogs.reports.travel = true
      }
    },
    downloadTimeReport() {
      if (this.dialogs.reports.time) {
        this.loading = true
        this.forms.reports.time.fechas.sort((a, b) => moment(a).diff(moment(b)))
        let fecha_inicio = this.forms.reports.time.fechas[0]
        let fecha_fin = this.forms.reports.time.fechas[1]
        this.axios
          .get(
            services.routes.semaphore +
              '/report/time' +
              '?empresa=' +
              this.$store.state.general.company +
              '&planta=' +
              this.control.planta +
              '&fecha_inicio=' +
              fecha_inicio +
              '&fecha_fin=' +
              fecha_fin
          )
          .then((response) => {
            if (response.data.data.length) {
              let data = []
              let headers = []
              for (let key in response.data.data[0]) {
                headers.push({
                  value: key,
                  fontWeight: 'bold'
                })
              }
              data.push(headers)
              for (let i = 0; i < response.data.data.length; i++) {
                let rows = []
                for (let key in response.data.data[0]) {
                  rows.push({
                    type: String,
                    value: !_.isNil(response.data.data[i][key]) ? response.data.data[i][key].toString() : ''
                  })
                }
                data.push(rows)
              }
              writeXlsxFile(data, { fileName: 'Reporte de tiempos.xlsx' })
              this.cancel()
            } else {
              this.error = true
              this.msgError = 'No hay información disponible'
            }
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.dialogs.reports.time = true
      }
    },
    setInterval() {
      this.control.interval = setInterval(() => {
        if (!this.dispatchView && !this.chartView) {
          this.getItems()
        } else if (this.dispatchView) {
          this.getDispatchItems()
        } else if (this.chartView) {
          // this.getChartItems()
        }
      }, 1000 * 60)
    }
  }
}
</script>

<style lang="css">
.text-normal {
  text-transform: none !important;
}
.delayed-order td {
  color: white;
  background-color: rgba(244, 67, 54, 0.7);
}
</style>
